import useSWR from "swr";

const fetcher = (url) => fetch(url).then((res) => res.text());

const useGetSiteSvgs = (typography, company) => {
  //Company svgs
  const { data: footer_name_company_1, isLoading: isLoadingFooterNameCompany } = useSWR(
    company ? `/assets/company/${typography}/${company}/footer_name_company_1.svg` : null,
    fetcher
  );

  const { data: footer_vat, isLoading: isLoadingFooterVat } = useSWR(
    company ? `/assets/company/${typography}/${company}/footer_vat.svg` : null,
    fetcher
  );

  const { data: footer_direction, isLoading: isLoadingFooterDirection } = useSWR(
    company ? `/assets/company/${typography}/${company}/footer_direction.svg` : null,
    fetcher
  );

  const { data: company_name, isLoading: isLoadingCompanyName } = useSWR(
    company ? `/assets/company/${typography}/${company}/company_name.svg` : null,
    fetcher
  );

  const { data: company_vat, isLoading: isLoadingCompanyVat } = useSWR(
    company ? `/assets/company/${typography}/${company}/company_vat.svg` : null,
    fetcher
  );

  const { data: address_1, isLoading: isLoadingAddress1 } = useSWR(
    company ? `/assets/company/${typography}/${company}/address_1.svg` : null,
    fetcher
  );

  const { data: registry_city, isLoading: isLoadingRegistryCity } = useSWR(
    company ? `/assets/company/${typography}/${company}/registry_city.svg` : null,
    fetcher
  );

  const isLoading =
    isLoadingFooterNameCompany ||
    isLoadingFooterVat ||
    isLoadingFooterDirection ||
    isLoadingCompanyName ||
    isLoadingCompanyVat ||
    isLoadingAddress1 ||
    isLoadingRegistryCity;

  return {
    data: {
      footer_name_company_1,
      footer_vat,
      footer_direction,
      company_name,
      company_vat,
      address_1,
      registry_city,
    },
    isLoading,
  };
};

export default useGetSiteSvgs;
